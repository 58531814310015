import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private http: HttpClient) { }

  getClients() {
    // Cambia la URL de acuerdo a tu configuración de Frappe Framework
    const url = 'https://erp.collective-energy.africa/api/resource/Clientes?fields=["*"]';
    return this.http.get(url);
  }
}
