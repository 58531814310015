<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size text-center">IMPULSANDO LATINO AMÉRICA</h1>
            <p style="color:#EFE6C0; font-size:large" class="mt-4">Servicios integrales que mejoran sus soluciones energéticas.</p><br>
            <div class="row justify-content-center">
            </div>
            <div id="mi-elemento"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="barra nav-about"  [ngClass]="{'nav-fixed': isNavFixed}">
      <p class="hover nav-letra">
        <a class="texto-negro letra-bold texto-chico" (click)="onClick('TECHNICAL')" [innerHTML]="'SOPORTE TÉCNICO'"></a>
      </p>
      <p class="hover nav-letra">
        <a class="texto-negro letra-bold texto-chico" (click)="onClick('TRAININGS')" [innerHTML]="'FORMACIÓN'"></a>
      </p>
      <p class="hover nav-letra">
        <a class="texto-negro letra-bold texto-chico" (click)="onClick('END')" [innerHTML]="'FIN DE LA VIDA ÚTIL'"></a>
      </p>
    </div>
    

  <div class="main-banner main-banner-imagen">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="card-cea" style="opacity: 0.94;">
            <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="row align-items-center ">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 style="text-align: left;">POR QUÉ ES IMPORTANTE NUESTRO SERVICIO</h1><br>
                      <p class="fuente-pop-reg">En Collective Energy LATAM (CE LATAM), el servicio no es sólo un añadido, sino el núcleo de lo que nos diferencia. Creemos que ofrecer soluciones energéticas de primer nivel es sólo el principio. Nuestro compromiso con nuestros clientes va más allá de la venta, garantizando que cada instalación funcione de forma óptima y fiable. Nuestro servicio experto, la formación práctica y el apoyo dedicado distinguen a CE LATAM como líder entre los distribuidores de BYD en Latino América.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video">
                    <img src="assets/img/LocalTraining.jpg" alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-70 main-banner-byd"id="TECHNICAL">
  <div class="main-banner-byd " >
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="d-flex justify-content-center">
            <h1 style="text-align: left; font-size: 50px; color: #ffffff;">Asistencia técnica</h1><br>
          </div>
          <div class="card-byd d-flex gap-3" style="opacity: 0.9;">
            <div class="card-body col-12" style= "background-color:#232e1f; ">

                <div class=" col-md-12 text-left ">
                  <h1 class="titulo text-left" >SOPORTE TÉCNICO LOCAL</h1>
                  <p class="fuente-pop-reg mb-5" style="color:#efe6c0;">
                    Nuestros equipos de servicio están estratégicamente ubicados en diferentes regiones, proporcionando una cobertura total en toda Latino América. Nuestros técnicos expertos están a sólo una llamada de distancia, listos para ayudarle con cualquier problema. Nos enorgullecemos de ofrecer asistencia adaptada a sus necesidades, con personas reales que entienden sus retos. Para obtener asistencia inmediata, póngase en contacto con nuestro equipo local de asistencia técnica por correo electrónico o llámenos al:
                  </p>
                  <div class="box">
                    <a style="color:black; font-size:large;" href="mailto:service@collective-energy.africa">
                      service&#64;collective-energy.lat
                    </a>
                  </div>
                  
               
                </div>
                </div>

                
             
               
                    <div class="card-byd col-lg-6 col-md-12 text-left" style="background-color:#232e1f;">
                        <div class="card-body" style= "background-color:#232e1f; ">
                          <div class="row d-flex justify-content-center">
                            <div class=" col-md-12 text-left justify-content-center">
                              <h1 class="titulo text-left" >RESPUESTA RÁPIDA Y RESOLUCIÓN DE PROBLEMAS</h1>
                              <p class="fuente-pop-reg mb-5" style="color:#efe6c0;"> Cuando necesita asistencia, el tiempo es oro. Nuestros servicios de respuesta rápida garantizan que cualquier problema técnico se resuelva con rapidez y eficacia. Hemos creado un centro de servicio en línea en el que puede registrar los problemas, seguir el progreso y acceder a los recursos en tiempo real. Visite nuestro Centro de servicios para empezar.</p>
                              <img style="color:#efe6c0;" src="/assets/img/Screenshot-Service-Centre.png" alt="about">
                                <a class="borde-btn mt-5 btn-support" href="https://support.collective-energy.africa/" target="_blank">servicio en línea</a>
                    </div>
              </div>

        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
  <div class=" main-banner-byd">
  <div class="main-banner-byd ptb-100" >
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
       
          <div class="card-byd d-flex gap-3" style="opacity: 0.9;">
            <div class="card-body" style= "background-color:#232e1f; ">

                <div class=" col-md-12 text-left ">
                  <h1 class="titulo text-left" >ALMACENES DE REPUESTOS PARA REEMPLAZO RÁPIDO</h1>
                  <p class="fuente-pop-reg mb-5" style="color:#efe6c0;">
                    Para minimizar tiempos de espera y asegurar una respuesta inmediata, contamos con almacenes de repuestos en diferentes países de la región. Esto nos permite proporcionar piezas de reemplazo de forma rápida y efectiva.
                    <br><br>
                  </p>
                  <div class="d-flex justify-content-center">

                  <img style="margin-top: 34%;" src="/assets/img/Wharehouse.jpg" alt="about">
                    </div>
                </div>
                </div>
                    <div class="card-byd col-lg-6 col-md-12 text-left" style="background-color:#232e1f;">
                        <div class="card-body" style= "background-color:#232e1f; ">
                          <div class="row d-flex justify-content-center">
                            <div class=" col-md-12 text-left justify-content-center">
                              <h1 class="titulo text-left" >EXPERIENCIA PRÁCTICA EN LA QUE PUEDE CONFIAR<br><br></h1>
                              <p class="fuente-pop-reg mb-5" style="color:#efe6c0;">Nuestro equipo de servicio técnico no sólo está formado por expertos, sino también por profesionales con amplia experiencia práctica. Desde la instalación hasta la resolución de problemas, nuestros expertos han trabajado directamente con los sistemas a los que damos soporte, lo que garantiza que usted reciba un servicio de primer nivel por parte de aquellos que conocen la tecnología por dentro y por fuera. Con CE LATAM, sus soluciones energéticas están en manos capaces y experimentadas.</p>  
                              <div class="d-flex justify-content-center">
                                <img  src="/assets/img/Expertise.jpg" alt="about">
                              </div>
                    </div>
              </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
  <div class="pt-70 fondo-blanco"  id="TRAININGS">
  <div class="ptb-100" id="TRAININGS">
  <h1 style="text-align: center; font-size: 50px; color: #000000;">PROGRAMAS LOCALES DE FORMACIÓN</h1><br>
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
            <div class="card-byd;" style="background-color: #0b1f0d;">
          <div style="color:#0b1f0d">
            <div class="card-body">
              <div class=" row align-items-center " style="padding: 10px;">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 class="texto-blanco">POTENCIAMOS EL TALENTO LOCAL</h1><br>
                      <p class="fuente-pop-reg texto-blanco" style="margin-left: 10%;">No nos limitamos a suministrar productos; capacitamos a las personas. A través de nuestros amplios programas de formación, dotamos a los técnicos locales de los conocimientos y habilidades necesarios para instalar, mantener y optimizar los sistemas de energía BYD. Nuestros módulos de formación están diseñados para cumplir con los estándares globales al tiempo que abordan las necesidades locales.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video">
                    <img src="/assets/img/Training 2.jpg" alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="bg-fffdfd ">
        <div class="container">
            <div class="section">
                <div class="">
                    <div class="section">
                        <div class="mb-5">
                            <h1 style="text-align: center;  color: #000000;">LABORATORIOS DE FORMACIÓN</h1>
                            <div>
                              <p class="texto-justidicado justify-content-center">Para garantizar una formación accesible y de alta calidad, CE LATAM ha establecido laboratorios de formación de última generación en lugares clave como Colombia. Estas instalaciones ofrecen formación práctica con la última tecnología de BYD, garantizando que los técnicos estén totalmente preparados para afrontar los retos de las soluciones modernas de almacenamiento de energía.</p><br>

                            </div>
                        </div>
                        <div class="row justify-content-center equal-height pb-100">
                            <div class="col-lg-3 col-md-6">
                              <div class="content-wrapper">
                                    <div class="services-content mb-2">
                                        <h3 class="borders-top">COLOMBIA –</h3>
                                        <span class="subtext">Centro Dymano en Facatativa<br></span><br>
                                    </div>
                                    <div class="services-img">
                                        <img src="assets/img/CENTRO-DE-FORMACIÓN-DYNAMO.jpg" alt="services-img">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                              <div class="content-wrapper">
                                    <div class="services-content mb-2">
                                        <h3 class="borders-top">COLOMBIA –</h3>
                                        <span class="subtext">SENA, la Guajira <br></span><br>
                                    </div>
                                    <div class="services-img">
                                        <img src="assets/img/CENTRO-DE-FORMACION-SENA-GUAJIRA.jpg" alt="services-img">
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </div>
      </div>
      <div class="ptb-100" style="background-color: #ffffff;">
        <h1 style="text-align: center; font-size: 50px; color: #000000;">Programas locales de formación.</h1><br>
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                  <div class="card-byd;" style="background-color: #0b1f0d;">
                <div style="color:#0b1f0d">
                  <div class="card-body" style="padding-top: 10px; padding-bottom: 10px;">
                    <div class=" row align-items-center " style="padding: 10px;">
                      <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                          <div class="section-title">
                            <h1 class="texto-empowering">PRÓXIMOS CURSOS DE FORMACIÓN</h1><br>
                            <p class="fuente-pop-reg texto-empowering" style="margin-left: 10%;">Organizamos periódicamente sesiones de formación en diferentes países de Latino América para asegurarnos de que nuestros socios e instaladores están al día de las últimas tecnologías y las mejores prácticas. A continuación encontrará una lista de nuestras próximas sesiones de formación. Haga clic en los enlaces para inscribirse directamente:</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12 text-center">
                        <div class="about-video">
                          <img src="/assets/img/Upcoming-trainings.jpg" alt="about">
                          <div class="video-btn">
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div *ngIf="datosFiltrados" class="pt-5">
            <div class="row justify-content-center">
              <div *ngIf="isLoading" class="main-banner-white-home mt-5 d-flex justify-content-center align-items-center" style="background-color: #F8F8F8; height: 300px;">
                <h3 class="text-center"><fa-icon [icon]="faSpinner"  [spin]="true"></fa-icon>
                  {{ 'Loading' }}</h3>
              </div>
              <div *ngFor="let dato of datosFiltrados; let i = index" class="col-md-3 d-flex justify-content-center">
                <div class="card texto-chico letra-bold mt-3 mx-auto" style="width: 35vh;" rel="noopener noreferrer">
                  <ng-container *ngIf="dato.nombre || dato.pais || dato.ciudad">
                    <b *ngIf="dato.nombre" class="text-center mt-1" style="width: 100%;">{{ dato.nombre + ',' }}</b>
                    <b *ngIf="dato.pais && dato.nombre" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.pais + ',' }}</b>
                    <b *ngIf="dato.ciudad && (dato.nombre || dato.pais)" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.ciudad }}</b>
                    <p *ngIf="dato.fecha" class="text-center mt-1" style="width: 100%; font-weight: 1;">{{ dato.fecha }}</p><br>
                  </ng-container>
                  <div *ngIf="dato.url_evento" class="text-center" style="background-color: #232e1f; padding: 10px; width: 100%;">
                    <a href="{{ dato.url_evento }}" target="_blank" [innerHTML]="'REGISTER'" style="color: white"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div *ngIf="datosFiltrados.length === 0 && !isLoading">
            <div class="row justify-content-center">
              <div class="card texto-chico letra-bold mt-3 mx-auto" style="width: 50%; height:auto" rel="noopener noreferrer">
                <p class="mt-3" [innerHTML]="'En este momento no hay formaciones disponibles. Consulte nuestra página con regularidad para estar al día'" style="color:red; font-weight:100"></p><br>
              </div>
            </div>
          </div>
          
          <div class="row justify-content-center">
            <div *ngFor="let dato of datos; let i = index" class="col-md-6 d-flex justify-content-center">
              <div class="card texto-chico letra-bold mt-3 mx-auto" style="width: 35vh;" rel="noopener noreferrer">
                <ng-container *ngIf="dato.nombre || dato.pais || dato.ciudad">
                  <b *ngIf="dato.nombre" class="text-center mt-1" style="width: 100%;">{{ dato.nombre + ',' }}</b>
                  <b *ngIf="dato.pais && dato.nombre" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.pais + ',' }}</b>
                  <b *ngIf="dato.ciudad && (dato.nombre || dato.pais)" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.ciudad }}</b>
                  <p *ngIf="dato.fecha" class="text-center mt-1" style="width: 100%; font-weight: 1;">{{ dato.fecha }}</p><br>
                </ng-container>
                <div  *ngIf="dato.url_evento" class="text-center" style="background-color: #232e1f; padding: 10px; width: 100%;">
                  <a href="{{ dato.url_evento }}" target="_blank" [innerHTML]="'REGISTER'" style="color: white"></a>
                </div>
              </div>
            </div>
          </div>
          </div >
         
          
          
          <div class="pt-70" id="END">
          <div class="pt-70" >
            <div class="d-table">
              <div class="d-table-cell">
                <div class="container mb-5">
                  <div class="card-cea" style="opacity: 0.94;">
                    <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
                      <div class="row align-items-center ">
                        <div class="col-lg-6 col-md-12">
                          <div class="about-content">
                            <div class="section-title">
                              <h1 style="text-align: center; font-size: 45px">¿QUÉ VIENE DESPUÉS DE LA VIDA ÚTIL DEL PRODUCTO?</h1><br>
                             <p> Una vez que una batería ya no es adecuada para su uso inicial, le encontramos una nueva utilidad o reciclamos sus componentes introduciéndolos en una economía circular. En cualquier caso, siempre garantizamos un nuevo comienzo para nuestras baterías.
                              Las normas de tratamiento de residuos de baterías son complejas y dependen de cada país. Sin embargo, en Collective Energy nos comprometemos a ofrecerle la mejor solución posible, independientemente de la ubicación de su instalación. Por lo tanto, si ha adquirido una de nuestras baterías a través de nosotros o de uno de nuestros socios, y desea que las recojamos, siga los pasos que se indican a continuación:</p><br>                          
                                <p class="fuente-pop-reg"><span style="font-weight: 550;">PROCESO</span><br>
                                    <p class="fuente-pop-reg"><span style="font-weight: 550;">1. Contacto:</span><br>
                                      Póngase en contacto con nosotros en: takeback&#64;collective-energy.lat e incluya la siguiente información: Todos los números de serie de sus pilas Díganos si necesita embalaje La dirección completa de recogida (nombre, calle, código postal, ciudad, país, teléfono, correo electrónico) Muy importante: díganos el estado de las pilas y la razón por la que cree que han llegado al final de su vida útil.
                                      <p class="fuente-pop-reg"><span style="font-weight: 550;"> 2. Recogida:</span><br>
                                        Nos pondremos en contacto con usted para informarle de las opciones de recogida y tratamiento más sostenibles.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12 text-center">
                          <div class="about-video">
                            <img src="/assets/img/LV5-TOWER-service.png" alt="about">
                            <div class="video-btn">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
  </div>
  <app-footer></app-footer>