import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmFormComponent } from '../confirm-form/confirm-form.component';
import { ContactService } from '../../services/contact.service';
import { ConfirmInquiriesComponent } from '../confirm-inquiries/confirm-inquiries.component';

@Component({
  selector: 'app-other-inquiries',
  templateUrl: './other-inquiries.component.html',
  styleUrls: ['./other-inquiries.component.scss']
})
export class OtherInquiriesComponent {
  firstFormGroup: FormGroup;

  constructor(private cd: ChangeDetectorRef, private _formBuilder: FormBuilder, private dialog: MatDialog, public contactService: ContactService) {
    this.firstFormGroup = this._formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      phone_number: [''],
      inquiry:['', Validators.required],
    });
  }

  submit(){
    if(this.firstFormGroup.invalid) {
      return;
    }
    console.log(this.firstFormGroup.value);
    let data = {
      "tipo": "Inquiry",
      "first_name": this.firstFormGroup.value.first_name,
      "last_name": this.firstFormGroup.value.last_name,
      "email": this.firstFormGroup.value.email,
      "phone_number": this.firstFormGroup.value.phone_number,
      "inquiry": this.firstFormGroup.value.inquiry,
    }
    
    this.contactService.submitInquiry(data).subscribe({
      next: (r: any) => {
        this.dialog.open(ConfirmInquiriesComponent, { disableClose: true });
        this.cd.detectChanges();
      },
      error: (e) => {
        console.log(e);
      }
    })
  }
}
