<div class="main-banner-home">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="main-banner-text">
          <h1 class="mobile-font-size pc-font-size">BYD BATTERYBOX</h1>
          <p style="color:#EFE6C0;margin-top:0.7em; font-size:large;">TECNOLOGÍA DE VANGUARDIA EN ALMACENAMIENTO DE ENERGÍA</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-fffdfd  pb-70" id="welcome-raion">
  <div class="container">
      <div class="section">
          <div class="parrafos">
              <div class="col-lg-6 welcome-title1">
                  <h1 ><span class="borders-bottom-green" >BYD</span> BatteryBox</h1>
              </div>
              <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2 ">
                  <p>En Collective Energy, nos asociamos con BYD, líder global en tecnología de baterías LFP (Litio-Hierro-Fosfato), para ofrecer las mejores soluciones de almacenamiento de energía disponibles en el mercado. Los sistemas de almacenamiento BYD BatteryBox están diseñados para proporcionar la máxima seguridad, eficiencia y durabilidad, asegurando una gestión energética óptima tanto para hogares como para grandes proyectos industriales.</p>
              </div>
          </div>
          <br>
          <div class="row justify-content-center">
            <h3 class="mt-5 mb-3">Bajo Voltaje</h3>
              <div class="col-lg-4 col-md-6">
                  <div class="single-services-byd">
                      <div class="services-content ">
                          <h2>LV Flex Lite</h2>
                          <small class="borders-bottom-red"> BYD BatteryBox - 5-320 kWh</small>
                      </div>
                      <div class="container-img text-center space-img" (mouseenter)="showText1()" (mouseleave)="hideText1()">
                          <img src="assets/img/LV-FLEX-LITE.png"  alt="services-img">
                          <span class="text">
                              <div class="services-content">
                                  <h2>LV Flex Lite</h2>
                                  <small class="borders-bottom-red">BYD BatteryBox - 5-320 kWh</small>
                              </div>
                              <ul class="fuente-pop-reg">
                                  <li>&bull; Batería de Litio-ferrofostato (LFP)</li>
                                  <li>&bull; 5kWh por módulo</li>
                                  <li>&bull; De 1 a 64 módulos en paralelo</li>
                                  <li>&bull; (5-320 kWh)</li>
                                  <li>&bull; Batería de bajo voltaje (48v)</li>
                                  <li>&bull; Garantía de 10 años</li>
                              </ul>
                                    <div class="d-flex justify-content-center">
                                      <a class="btn btn-byd" style="margin-top: 12em;font-size:small" href="https://cms.eft-systems.de//files/BBOX_LV%20FLEX%20LITE_Datasheet_EN_V1.0_240708_L-668f93922a6e4.pdf" target="_blank" rel="noopener noreferrer">
                                        DESCARGAR FICHA TÉCNICA →
                                      </a>
                                  </div>
                          </span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="single-services-byd">
                  <div class="services-content ">
                      <h2>Premium LVL</h2>
                      <small class="borders-bottom-red">BYD BatteryBox - 15.4-983kWh</small>
                    </div>
                  <div class="container-img d-flex justify-content-center align-items-center text-center space-img" (mouseenter)="showText2()" (mouseleave)="hideText2()">
                    <img class="text-center " src="/assets/img/LVL-DOWNLOAD.png" alt="services-img">
                    <!-- <img class="text-center" src="/assets/img/LV5-tower.png" alt="services-img"> -->
                    <span class="text">
                      <div class="services-content ">
                          <h2>Premium LVL</h2>
                          <small class="borders-bottom-red">BYD BatteryBox - 15.4-983kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Batería de Litio-ferrofosfato (LFP)</li>
                          <li>&bull; 15.4kWh por módulo</li>
                          <li>&bull; De 1 a 64 módulos en paralelo</li>
                          <li>&bull; (15.4 - 983kWh)</li>
                          <li>&bull; Batería de bajo voltaje (48v)</li>
                          <li>&bull; Garantía de 10 años</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top: 12em;font-size:small" href="https://cms.eft-systems.de//files/BBOX_LVL_Datasheet_EN_V1.0_240626_L-668f948a29616.pdf" target="_blank" rel="noopener noreferrer">
                          DESCARGAR FICHA TÉCNICA →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="single-services-byd">
                  <div class="services-content">
                      <h2>LV5.0</h2>
                      <small class="borders-bottom"> BYD Findreams - 5-160kWh</small>
                    </div>
                  <div class="container-img text-center" (mouseenter)="showText3()" (mouseleave)="hideText3()">
                    <img src="/assets/img/LV5-tower.png"  alt="services-img">
                    <span class="text">
                      <div class="services-content">
                          <h2>LV5.0</h2>
                          <small class="borders-bottom"> BYD Findreams - 5-160kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Batería de Litio-ferrofostato (LFP)</li>
                          <li>&bull; 5kWh por módulo</li>
                          <li>&bull; De 1 a 32 módulos en paralelo(5 - 160 kWh)</li>
                          <li>&bull; Batería de bajo voltaje (48v)</li>
                          <li>&bull; Garantía de 7 años</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top:11em; font-size:small" href="https://cms.eft-systems.de//files/BATTERYBOX%20DATASHEET%20LV5.0.pdf" target="_blank" rel="noopener noreferrer">
                          DESCARGAR FICHA TÉCNICA →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
              <h3 class="mt-5 mb-3">ALTO VOLTAJE</h3>
              <div class="col-lg-6 col-md-6">
                <div class="single-services-byd d-flex flex-column align-items-center justify-content-center">
                  <div class="services-content">
                      <h2>Premium HVS</h2>
                      <small class="borders-bottom">BYD BatteryBox - 5.1-38.4kWh</small>
                    </div>
                  <div class="text-center " (mouseenter)="showText3()" (mouseleave)="hideText3()">
                    <img src="/assets/img/HVS.png"  alt="services-img">
                    <span class="text">
                      <div class="services-content">
                          <h2>Premium HVS</h2>
                          <small class="borders-bottom">BYD BatteryBox - 5.1-38.4kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Batería de Litio-ferrofostato (LFP)</li>
                          <li>&bull; 2.56kWh por módulo</li>
                          <li class="no-wrap">&bull; De 1 torre de 2-5 módulos (5.1-12.8kWh) hasta 3 torres en paralelo (38.4kWh)</li>
                          <li>&bull; Batería de alto voltaje</li>
                          <li>&bull; Garantía de 10 años</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top:11em; font-size:small" target="_blank" href="https://cms.eft-systems.de//files/BBOX_HVM_Datasheet_EN_V1.0_240703_L-668f8e06c4476.pdf">
                          DESCARGAR FICHA TÉCNICA →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="single-services-byd d-flex flex-column align-items-center justify-content-center">
                  <div class="services-content">
                      <h2>Premium HVM</h2>
                      <small class="borders-bottom">BYD BatteryBox - 8.3-66.2 kWh</small>
                    </div>
                  <div class="text-center ml-5" (mouseenter)="showText3()" (mouseleave)="hideText3()">
                    <img src="assets/img/HVM.png" alt="services-img" >
                    <span class="text">
                      <div class="services-content">
                          <h2>Premium HVM</h2>
                          <small class="borders-bottom">BYD BatteryBox - 8.3-66.2 kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Batería de Litio-ferrofostato (LFP)</li>
                          <li>&bull; HVM 2.76 kWh por módulo hasta 3 torres en paralelo (66.2 kWh)</li>
                          <li>&bull;  De 1 torre de 3 a 8 módulos (de 8.3 a 22.1 kWh)</li>
                          <li>&bull; Batería de alto voltaje</li>
                          <li>&bull; Garantía de 10 años</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top:11em; font-size:small" target="_blank" href="https://cms.eft-systems.de//files/BBOX_HVM_Datasheet_EN_V1.0_240703_L-668f8e06c4476.pdf" rel="noopener noreferrer">
                          DESCARGAR FICHA TÉCNICA →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div class=" bg-fffdfd   pb-70" id="welcome-raion">
    <div class="container">
        <div class="section">
<div class="   " id="welcome-raion">
    <div class="section">
        <div class="battery mb-5">
            <span>Batterybox</span>
            <span>Ventajas</span>
            </div>         
            <br>   
            <div class="row justify-content-center equal-height">
            <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3> Aplicaciones <br> Off-Grid </h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/OffGridApplications.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <small>Las aplicaciones Off-Grid y el uso de energía de emergencia son algunos de los principales usos de las Battery-Box. La alta capacidad de descarga permite el funcionamiento desconectado de la red eléctrica. Ya sea que su proyecto suministre energía a una cabaña remota en las montañas o en una isla perdida o se utilice como respaldo para cargas sensibles, BYD tiene el almacenamiento adecuado para usted.</small>
                        
                    </div>
                </div>
            </div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content" style="margin-bottom: 13%;">
            <h3>Autoconsumo</h3>                        
        </div>
        <div class="services-img">
            <img src="assets/img/SelfConsuptionOptimization.jpg" alt="services-img">
        </div>
        <div class="services-content">
            <small>El autoconsumo de energía renovable es el futuro. A medida que en todo el mundo disminuyen las subvenciones para la generación de energía renovable y aumentan los precios de la electricidad, se vuelve más económico consumir la energía que generas tú mismo.</small>
            
        </div>
    </div>
</div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content">
            <h3>Aplicaciones <br> Industriales</h3> <br>                           
        </div>
        <div class="services-img">
            <img src="assets/img/IndustrialApplications.jpg" alt="services-img">
        </div>
        <div class="services-content">
            <small>Desde viviendas unifamiliares hasta aplicaciones comerciales. Si puedes diseñarlo, puedes utilizar la BatteryBox para construirlo. Como instalador, deseas trabajar con el mismo bloque de construcción común adaptándolo a las necesidades de tus clientes. Incluso puedes comenzar de manera más pequeña e ir agregando capacidad en cualquier momento de la vida del producto.</small>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
    </div>
</div>
<div class="pb-70 pt-70" style="background-color: #EFE6C0;">
    <div class="section">
<div class="battery" >
    <span style="margin-bottom: 2%;">¿QUÉ</span>
    <span>OFRECEMOS?</span>
    </div>         
    <br><br> 
    <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/sustainable.svg" height="250" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Sostenible</h2><br>
                <small class="fuente-pop-reg">Un paso importante para las energías renovables descentralizadas y efectivas.</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class=""  >
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/economical.svg" height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Económico</h2><br>
                <small class="fuente-pop-reg">Ahorra dinero y aumenta la autosuficiencia aumentando el autoconsumo.</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/safe.svg"  height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Seguro</h2><br>
                <small class="fuente-pop-reg">Las robustas baterías de ion litio y hierro cumplen con los requisitos más altos de seguridad y calidad.</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/local.svg" height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Local</h2><br>
                <small class="fuente-pop-reg">Disfrute del soporte de nuestro equipo local.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</div>
<div class="pb-70 pt-70 " style="background-color: #ffffff; ">
    <div class="section  ptb-100">
<div class="battery" >
    <span style="opacity: 0.5;">PREMIOS</span>
    </div>         
    <br><br> 
    <div style="text-align:center; width: 100%;">
        <small style="display: inline-block; width: 100%; max-width: 600px; text-align: center; line-height: 1.5; font-size: 0.7rem;" class="fuente-pop-reg">
          La BatteryBox cumple las normas de seguridad más estrictas, como la VDE 2510-50 (HVS/HVM/LVS), y recibe numerosos premios y sellos. En la inspección independiente de almacenamiento de energía de la universidad HTW de Berlín, la BatteryBox se clasifica como una de las baterías con la mayor eficiencia del mercado.
        </small>
      </div>
          <div class="container" >
    <div class="row col-10 mx-auto" >
        <div class="col-md-3">
            <div class="services-img ">
            <img src="assets/img/PV MAGAZINE.jpg" alt="services-img">
            </div>
        </div>
        <div class="col-md-3">
            <div class="services-img size-img-resp">
            <img src="assets/img/EUPD.jpg" height="250px" alt="services-img">
            </div>
        </div>
        <div class="col-md-3">
            <div class="services-img size-img-resp">
            <img src="assets/img/ENERGY STORAGE.jpg"  height="250px" alt="services-img">
            </div>
        </div>
        <div class="col-md-3">
            <div class="services-img size-img-resp">
            <img src="assets/img/TUV.jpg" height="250px" alt="services-img">
        </div>
        </div>
    </div>
    </div>
</div>
</div>
<div class="pt-100 pb-70" style="background-color: #0B1F0D;">
    <div class="container">
        <div class="section">
    <div class="parrafos">
        <div class="col-lg-6 welcome-title1">
            <h1 style="color: white; font-size:60px; text-align:start">¿Quiere saber más <br>sobre nuestros productos?</h1>
            <div class="col-lg-12 col-md-12 " [class.active]="currentSection === 'Contact'">
              <a routerLink="/Contact" [queryParams]="{ section: 'Contact' }"><button type="submit" style="height: 40px; width:230px;" class="btn boton fuente-pop-reg">Contáctenos →</button></a>
          </div>
    </div>
    </div>
</div>
</div>
</div>