<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size">
              TU SOCIO DE CONFIANZA PARA 
              <br> INFRAESTRUCTURA DE CARGA DE VE</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-banner-byd">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="" style="opacity: 0.9;">
            <div class="card-body" >
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content text-center">
                    <div class="section-title">
                      <div class="about-video">
                        <img src="/assets/img/Coche.jpg" alt="about">
                        <div class="video-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  <p class="text-justify fuente-pop-reg" style="color: #efe6c0; ">
                    A medida que los vehículos eléctricos se convierten en el futuro del transporte, es crucial garantizar una infraestructura de carga fiable y eficiente. En CE LATAM, ofrecemos soluciones integrales de carga de vehículos eléctricos adaptadas a las necesidades residenciales, comerciales e industriales. Desde la instalación perfecta hasta el mantenimiento continuo y la gestión avanzada de la energía, nuestros servicios están diseñados para mantener sus puntos de carga totalmente operativos y optimizados.
                  </p>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pb-70 pt-70" style="background-color:#0b1f0d;">
    <div class="section">
<div class="battery" >
    <h1 class="mobile-font-size pc-font-size" style="color: #ffffff;">
      QUÉ OFRECEMOS
    </h1>
    </div>         
    <br><br> 
    <div class="container">
        <div class="row d-flex flex-row gap-3 justify-content-center">
          <div class="col-md-2">
            <div class="">
              <div class="caja residential-img">
                <img src="/assets/img/Icono_1.svg" height="100px" alt="residential-img">
              </div>
              <div class="title-h4 mt-2">
                <h4  class="mt-2">
                  Puntos de carga residenciales
                </h4><br>
                </div>
                <div class="letra-iconos">
                  <small class="fuente-pop-reg">
                    Instalación eficaz y segura de puntos de recarga de vehículos eléctricos domésticos adaptados a sus necesidades.
                  </small>

                </div>

            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_2.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 class="mt-2">
                  Puntos de carga comerciales
                </h4>
                </div>
                <div class="letra-iconos">
                <small class="fuente-pop-reg">
                  Soluciones de carga escalables para empresas, que garantizan una integración perfecta con sus operaciones.
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_3.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 class="mt-2">Desarrollo de proyectos</h4>
                </div>
                <div class="letra-iconos">
                <small class="fuente-pop-reg">
                  Desarrollo integral de proyectos de recarga de VE, desde la planificación hasta la implantación.
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_4.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 style="color:#ffffff">
                  Mantenimiento y servicio
                </h4>
              </div>
              <div class="letra-iconos">
                <small class="fuente-pop-reg">
                  Mantenimiento fiable y servicio rápido para mantener operativos sus puntos de recarga.
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class=""  >
              <div class="caja services-img" style="vertical-align: top;">
                <img src="/assets/img/Icono_5.svg" height="100px" alt="services-img">
              </div>
              <div class="title-h4 mt-2">
                <h4 style="color:#ffffff">Sistema de gestión de la energía (EMS)
                </h4>
                </div>
                <div class="letra-iconos">

                <small class="fuente-pop-reg">
                  Integración avanzada de EMS para optimizar infraestructuras complejas de recarga de vehículos eléctricos.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</div>
