<body>
    <div class="main-banner-home">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="main-banner-text-ci">
                    <h1 class="mobile-font-size pc-font-size" style="color:#ffffff; margin-top: 0.1em;margin-bottom: 0em;">C&I Solutions</h1>
                    <p class="text-center" style="color: #EFE6C0; margin-top: 0; font-size: large;">Soluciones energéticas comerciales e industriales (C&I) de BYD: fiables, rentables y escalables, adaptadas a las necesidades de tu negocio.</p>
                </div>
            </div>
        </div>
    </div>
    <div class=" bg-fffdfd   pb-70" id="welcome-raion">
        <div class="container">
            <div class="section">
    <div class="   " id="welcome-raion">
        <div class="section">
            <div class="battery-new mb-5 pt-5">
                <div class="battery-c mb-5">
                    <span>QUÉ OFRECEMOS</span>
                    </div>                     </div>         
                <br>   
                <div class="row justify-content-center equal-height">
                <div class="col-lg-4 col-md-6">
                    <div class="">
                        <div class="services-content title-space">
                            <h3>Soluciones energéticas personalizadas</h3>                         
                        </div>
                        <div class="services-img mt-3">
                            <img src="/assets/img/ci1.png" alt="services-img">
                        </div>
                        <div class="services-content">
                            <small>Almacenamiento de baterías y soluciones solares a medida, con baterías BYD, electrónica de potencia y avanzados sistemas de gestión energética.
                            </small>
                            
                        </div>
                    </div>
                </div>
    <div class="col-lg-4 col-md-6">
        <div class="">
            <div class="services-content title-space">
                <h3 >Entrega de proyectos llave en mano</h3>
                </div>
            <div class="services-img mt-3">
                <img src="/assets/img/medio-ci.jpeg" alt="services-img">
            </div>
            <div class="services-content">
                <small>Soporte integral desde el diseño e ingeniería hasta la instalación y el mantenimiento a largo plazo.
                </small>
                
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6">
        <div class="">
            <div class="services-content title-space">
                <h3>Experiencia local con respaldo global</h3>                         
            </div>
            <div class="services-img mt-3">
                <img src="/assets/img/ciderecha.jpeg" alt="services-img">
            </div>
            <div class="services-content">
                <small>Un equipo dedicado en Latino América, respaldado por EFT-Systems, líder europeo en soluciones energéticas para C&I.
                </small>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
        </div>
    </div>
    <div class="pb-70 pt-70 fondo-ci" style="background-color:#0b1f0d;">
        <div class="section">
            <div class="battery">
                <h1 class="mobile-font-size pc-font-size" style="color: #ffffff;">NUESTRAS SOLUCIONES</h1>
            </div>         
            <div class="d-flex justify-content-center">
                <p class="texto-blanco">
                    Nuestros sistemas están diseñados para satisfacer las necesidades energéticas de empresas en diversas industrias y aplicaciones.
                </p> 
            </div>
            <br><br> 
            <section class="use-cases-img row mt-5">
                <div class="imagenes row">  
                    <div class="col-md-2">
                        <a style="cursor: pointer;">
                            <img class="imagen" src="/assets/img/ci22.jpg" alt="">
                            <div class="mt-2 sub-title-space">
                                <b class="texto-blanco ">Protección ante cortes de red</b>
    
                            </div>
                            <div class="mt-4 ">
                                <small class="texto-blanco text-space">Mantén tus operaciones activas incluso en apagones con soluciones de almacenamiento de energía confiables.
                                </small>
                            </div>
                            <div class="mt-4 brouchure">
                                <a href="/assets/pdf/130325_brochure_CELATAM_c&i_case1.pdf" target="_blank" class="texto-negro boton-brouchure p-2">Descargar folleto del caso</a>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a style="cursor: pointer;">
                            <img class="imagen" src="/assets/img/ci331.png" alt="">
                            <div class="mt-2 sub-title-space">
                            <b class="texto-blanco">Optimización del coste energético</b>
                            </div>
                            <div class="mt-4 ">
                            <small class="texto-blanco">Almacena energía en horas de menor costo y úsala cuando la electricidad sea más cara.
                            </small>
                                </div>
                        </a>
                        <div class="mt-4 brouchure">
                            <a href="/assets/pdf/130325_brochure_CELATAM_c&i_case2.pdf" target="_blank" class="texto-negro boton-brouchure p-2">Descargar folleto del caso</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <a style="cursor: pointer;">   
                            <img class="imagen" src="/assets/img/Cut_diesel.png" alt="">
                            <div class="mt-2 sub-title-space">
                            <b class="texto-blanco">Reducción de costos de diésel
                            </b>
                            </div>
                            <div class="mt-4">
                            <small class="texto-blanco">Integra el almacenamiento de baterías con generadores diésel para reducir los costes de combustible y ampliar las horas de funcionamiento.
                            </small>
                                </div>
                        </a>
                        <div class="mt-4 brouchure">
                            <a href="/assets/pdf/130325_brochure_CELATAM_c&i_case3.pdf"target="_blank" class="texto-negro boton-brouchure p-2">Descargar folleto del caso</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <a style="cursor: pointer;">
                            <img class="imagen" src="/assets/img/ROI.jpg" alt="">
                            <div class="mt-2 sub-title-space">
                            <b class="texto-blanco">Soluciones escalables para el crecimiento</b>
                            </div>
                            <div class="mt-4">
                            <small class="texto-blanco">
                                Expande tus instalaciones sin costosas ampliaciones de red, integrando un sistema BESS inteligente.
                            </small>
                                </div>
                        </a>
                        <div class="mt-4 brouchure">
                            <a href="/assets/pdf/130325_brochure_CELATAM_c&i_case4.pdf"target="_blank" class="texto-negro boton-brouchure p-2">Descargar folleto del caso</a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <a style="cursor: pointer;">
                            <img class="imagen" src="/assets/img/Grow.jpg" alt="">
                            <div class="mt-2 sub-title-space">
                            <b class="texto-blanco ">Maximización de la energía renovable</b>
                            </div>
                            <div class="mt-4">
                            <small class="texto-blanco">
                                Almacena el excedente de energía solar para usarlo más tarde o inyectarlo a la red y aumentar la rentabilidad.
                            </small>
                                </div>
                        </a>
                        <div class="brouchure">
                            <a href="/assets/pdf/130325_brochure_CELATAM_c&i_case5.pdf"target="_blank" class="texto-negro boton-brouchure p-2">Descargar folleto del caso</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="bg-fffdfd pt-70 pb-70" id="welcome-raion">
    <div class="container resp-mt-2">
        <div class="section">
            <div class="parrafos">
                <div class="col-lg-6 welcome-title1">
                    <h1>PRODUCTOS</h1>
                </div>
                <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2">
    
                </div>
            </div>
            <br>
            <div *ngIf="!abrirFlex && !abrirPlus" class="row justify-content-center">
                <div class="col-lg-5 col-md-6" (click)="flex()">
                    <div class="single-services-byd space-img">
                        <div class="services-content">
                            <h3 class="fuente-cool-light">INDUSTRIAL FLEX</h3>
                        </div>
                        <div class="container-img text-center">
                            <img src="/assets/img/flex1.png" height="80%" alt="services-img">
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-6" (click)="plus()">
                    <div class="single-services-byd">
                        <div class="services-content">
                            <h3 class="fuente-cool-light">INDUSTRIAL PLUS</h3>
                        </div>
                        <div class="container-img text-center">
                            <img src="/assets/img/Container.png"  alt="services-img">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="abrirFlex" class="row justify-content-center">
                <div *ngIf="abrirFlex" class="col-lg-3 col-md-4">
                    <div class="single-services-byd space-img">
                        <div class="services-content">
                            <h4 class="fuente-cool-light">INDUSTRIAL FLEX 1</h4>
                        </div>
                        <div class="container-text text-center d-flex algin-text-center">
                            <ul class="text-start">
                                <li>● Potencia: 200kW (x1 Módulo SINENG PCS)</li>
                                <li class="mt-3">● Capacidad de energía: Energía de celda de 466 kWh (x1 gabinete cubo BYD MC)</li>
                                <li class="mt-3">● Voltaje CA nominal: 400 Vca (±15%)</li>
                                <li class="mt-3">● Dimensiones (Ancho x Fondo x Alto): 2250 x 1238 x 2774 mm</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="single-services-byd space-img">
                        <div class="services-content">
                            <h4 class="fuente-cool-light">INDUSTRIAL FLEX 2</h4>
                        </div>
                        <div class="container-text text-center">
                            <ul class="text-start">
                                <li>● Potencia: 200kW (x1 Módulo SINENG PCS)</li>
                                <li class="mt-3">● Capacidad de energía: Energía de celda de 932 kWh (x2 gabinetes cúbicos BYD MC)</li>
                                <li class="mt-3">● Voltaje CA nominal: 400 Vca (±15%)</li>
                                <li class="mt-3">● Dimensiones (An x Pr x Al): 3.380 x 1.238 x 2.774 mm</li>
                            </ul>                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="single-services-byd">
                        <div class="services-content">
                            <h4 class="fuente-cool-light">INDUSTRIAL FLEX 3</h4>
                        </div>
                        <div class="container-text text-center">
                            <ul class="text-start">
                                <li>● Potencia: 400kW (x2 Módulos SINENG PCS)</li>
                                <li class="mt-3">● Capacidad de energía: Energía de celda de 932 kWh (x2 gabinetes cúbicos BYD MC)</li>
                                <li class="mt-3">● Voltaje CA nominal: 400 Vca (±15%)</li>
                                <li class="mt-3">● Dimensiones (An x Pr x Al): 3.380 x 1.238 x 2.774 mm</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="volver" (click)="back()">Atrás</button>
                </div>
            </div>
            <div *ngIf="abrirPlus" class="row justify-content-center">
                <div class="col-lg-3 col-md-4">
                    <div class="single-services-byd space-img">
                        <div class="services-content">
                            <h4 class="fuente-cool-light">INDUSTRIAL PLUS 1</h4>
                        </div>
                        <div class="container-text text-center d-flex algin-text-center">
                            <ul class="text-start">
                                <li>● Potencia: 860kW</li>
                                <li class="mt-3">● Capacidad de energía (energía de la celda): 1864 kWh (x4 gabinetes cúbicos BYD MC)</li>
                                <li class="mt-3">● Voltaje CA nominal: 690 Vca (±15%)</li>
                                <li class="mt-3">● Dimensiones (Ancho x Fondo x Alto): Contenedor HQ de 20 pies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="single-services-byd space-img">
                        <div class="services-content">
                            <h4 class="fuente-cool-light">INDUSTRIAL PLUS 2</h4>
                        </div>
                        <div class="container-text text-center">
                            <ul class="text-start">
                                <li>● Potencia: 1.075kW</li>
                                <li class="mt-3">● Capacidad de energía (energía de la celda): 2330 kWh (x5 gabinetes cúbicos BYD MC)</li>
                                <li class="mt-3">● Voltaje CA nominal: 690 Vca (±15%)</li>
                                <li class="mt-3">● Dimensiones (Ancho x Fondo x Alto): Contenedor HQ de 20 pies</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="single-services-byd">
                        <div class="services-content">
                            <h4 class="fuente-cool-light">INDUSTRIAL PLUS 3</h4>
                        </div>
                        <div class="container-text text-center">
                            <ul class="text-start">
                                <li>● Potencia: 1.290kW</li>
                                <li class="mt-3">● Capacidad de energía (energía de la celda): 2796 kWh (x6 gabinetes cúbicos BYD MC)</li>
                                <li class="mt-3">● Voltaje CA nominal: 690 Vca (±15%)</li>
                                <li class="mt-3">● Dimensiones (Ancho x Fondo x Alto): Contenedor HQ de 20 pies</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="volver" (click)="back()">Atrás</button>
                </div>
            </div>
    
        </div>
    </div>
    </div>
    <div class="pb-70 pt-70" style="background-color:#0b1f0d;">
    <div class="container">      
        <div class="d-flex justify-content-left flex-column">
            <p class="texto-blanco">
                ¿Listo para optimizar tu estrategia energética?
            </p> 
            <p class="texto-blanco">
                Póngase en contacto con nuestro equipo hoy para explorar las mejores soluciones energéticas para su negocio.
            </p> 
            <p class="texto-blanco">
                Seleccione la opción que mejor describa su consulta y lo guiaremos al formulario apropiado:
            </p> 
            <div  class="row justify-content-center mt-5">
                <div class="col-lg-4 col-md-4">
                    <div class="card-ci ">
                        <div class="">
                            <h4 class="fuente-cool-light padding">TENGO UN PROYECTO PARA COTIZAR
                            </h4>
                        </div>
                        <div class=" text-left d-flex algin-text-left">
                            <p class="p-5">
                                ¿Estás listo para obtener una cotización para un proyecto específico? Háganos saber los detalles de su proyecto y nuestro equipo le brindará una solución personalizada.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <a href="/project-to-quote" class="texto-negro boton-brouchure p-2">Iniciar una solicitud de cotización</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="card-ci ">
                        <div class="">
                            <h4 class="fuente-cool-light padding">
                                SOY UN CLIENTE FINAL INTERESADO EN BATERÍAS
                            </h4>
                        </div>
                        <div class=" text-left d-flex algin-text-left">
                            <p class="p-5">
                                ¿Tiene curiosidad por saber si el almacenamiento de energía es la solución adecuada para sus necesidades? Complete este formulario y nuestros expertos lo guiarán a través de los beneficios del almacenamiento en batería para su situación específica.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <a href="/customer-interested" class="texto-negro boton-brouchure p-2">Más información</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="card-ci ">
                        <div class="">
                            <h4 class="fuente-cool-light padding">OTRAS CONSULTAS</h4>
                        </div>
                        <div class=" text-left d-flex algin-text-left">
                            <p class="p-5">
                                Si tiene alguna otra pregunta o desea obtener más información, estaremos encantados de ayudarle. Comuníquese con nosotros aquí.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <a href="/other-inquiries" class="texto-negro boton-brouchure p-2">Formulario de consulta general</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </body>