<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size">IMPULSANDO EL FUTURO ENERGÉTICO EN LATINOAMÉRICA
</h1>

            <div class="row justify-content-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-banner main-banner-imagen">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="card-cea" style="opacity: 0.94;">
            <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="row align-items-center ">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 style="text-align: left; font-size: 50px">COLLECTIVE ENERGY LATAM</h1><br>
                      <p class="fuente-pop-reg">En Collective Energy LATAM, ofrecemos soluciones avanzadas de almacenamiento de energía en toda América Latina. Con subsidiarias en Colombia y Chile, garantizamos un servicio cercano y personalizado en cada región.
                        <br><br>
                        Nos especializamos en la venta de sistemas de almacenamiento de energía basados en la tecnología LFP de BYD, asegurando máxima seguridad y eficiencia. Nuestro equipo de expertos locales maneja proyectos de diferentes escalas, desde soluciones residenciales hasta comerciales e industriales, adaptadas a las necesidades de cada cliente.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video">
                    <img src="/assets/logo-latam.png" style="height: 15rem; width: auto;" alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <article class="main-banner-home pt-70  d-flex justify-content-center align-items-center">
    <div class="  flex-column">
      <h2 class="texto-blanco d-flex justify-content-center">
        PARTE DEL GRUPO COLLECTIVE ENERGY
      </h2>
      <div class="row">
        <div class="col-2 p-0"></div>
        <div class="col-8">
          <p class="texto-secundario text-center mt-3 ">
            Collective Energy LATAM forma parte del Collective Energy Group, un grupo global que también incluye a Collective Energy Africa. Fundada en Kenya hace escasos 4 años, Collective Energy África se ha convertido rápidamente en el distribuidor número 1 de baterías LFP en East y West Africa, gracias a su compromiso con la innovación y el servicio de calidad.
<br><br>
Juntos, en Collective Energy Group combinamos experiencia internacional y soluciones adaptadas a las particularidades de cada mercado, promoviendo un futuro energético más eficiente y sostenible en todo el mundo.

          </p>
        </div>
        <div class="col-2"></div>
      </div>
      <!-- <div class="d-flex flex-row justify-content-evenly row mt-5 gap-3 p-2"> 
        <div class="col-sm-12 col-lg-3">
          <h6 class="texto-verde text-center">KENYA</h6>
          <p class="texto-blanco justificar">The birthplace of our mission and
            the foundation of our expertise. As
            the largest distributor of lithium-ion
            batteries in East Africa, Collective
            Energy Kenya continues to lead
            the charge in delivering
            cutting-edge energy storage
            solutions.</p>
        </div>
        <div class="col-sm-12 col-lg-3">
          <h6 class="texto-verde text-center">UGANDA</h6>
          <p class="texto-blanco justificar">Expanding our footprint in East
            Africa, Collective Energy Uganda
            is dedicated to providing the same
            level of quality and service that has
            made us a trusted name in Kenya.
            Our Ugandan operations focus on
            empowering local communities
            with reliable energy storage and
            technical support.</p>
        </div>
        <div class="col-sm-12 col-lg-3">
          <h6 class="texto-verde text-center">SOUTH AFRICA</h6>
          <p class="texto-blanco justificar">As our southernmost presence,
            Collective Energy South Africa
            serves as a hub for our operations
            in the southern regions of the
            continent. We offer BYD batteries
            tailored to local needs, ensuring
            reliable power for homes and
            businesses alike..</p>
        </div>
      </div> -->

    </div>


  </article>
  

  <div class="main-banner-byd ptb-100">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="battery battery-cea">
            <span class="m-5" style="color:#FFFFFF">PARTNERS DE BYD</span>
          </div>
          <div class="card-byd" style="opacity: 0.9;">
            <div class="card-body" style="padding: 40px;">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content text-center">
                    <div class="section-title">
                      <div class="about-video">
                        <img src="assets/img/cealogos.png" alt="about">
                        <div class="video-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  <!-- <h1 class=""style="text-align: left; font-size: 50px;margin-left:0.8em;">Build Your Dreams</h1><br> -->
                  <p class="text-justify fuente-pop-reg" style="margin-left:3em; margin-right:3em">En Collective Energy, trabajamos en estrecha colaboración con BYD, líder global en soluciones de almacenamiento de energía y tecnología de baterías LFP (fosfato de hierro y litio). Nuestra elección de BYD como partner no es casual: su compromiso con la innovación, la sostenibilidad y la seguridad lo convierte en el aliado perfecto para ofrecer soluciones energéticas de vanguardia en América Latina.
                  </p>
                  <div class="button-container">
                    <a href="https://www.bydbatterybox.com/" target="_blank" class="btn btn-three ms-5 fuente-pop-reg">MÁS INFORMACIÓN SOBRE BYD →</a>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="bg-fffdfd ptb-100">
    <div class="container">
      <div class="section">
        <div class="">
          <div class="section">
            <div class="battery">
              <span>¿Por qué  
              </span>
              <span class="mt-5">elegimos</span>
              <span class="mt-5">a BYD?</span>
            </div>
            <div class="row justify-content-center equal-height">
              <div class=" small-padd">
                <small class="fuente-pop-reg economy-text">Nuestra asociación con BYD nos permite ofrecer soluciones confiables y de alto rendimiento. Al combinar su tecnología líder con nuestro servicio personalizado y experiencia local, logramos el equilibrio perfecto para brindar a nuestros clientes las mejores soluciones energéticas disponibles en el mercado.

                </small>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="">
                  <div class="services-content">
                    <h3><span class="borders-top">Tecnolo</span>gía de  <br> vanguardia:</h3> <br>
                    <p class="col-lg-10">BYD es pionero en el desarrollo de baterías LFP, reconocidas 
                      por su seguridad, eficiencia y durabilidad.<br><br><br>
                    </p>
                  </div>
                  <div class="services-img d-flex justify-content-center">
                    <img src="/assets/img/Tecnologia de vanguardia.svg" height="250"width="auto" alt="services-img">
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3 ><span class="borders-top">Lideraz</span>go  <br> global:</h3> <br>    
                        <p class="col-lg-10">Con décadas de experiencia, BYD es el fabricante líder de soluciones de 
                          almacenamiento de energía y vehículos eléctricos a nivel mundial. <br><br>

                        </p>                        
                    </div>
                    <div class="services-img d-flex justify-content-center">
                        <img src="/assets/img/Liderazgo global.svg" height="250"width="auto" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3><span class="borders-top">Compro</span>miso con  <br> la sostenibilidad</h3> <br> 
                        <p class="col-lg-10">Las soluciones de BYD ayudan a reducir las emisiones de carbono y a optimizar 
                          el uso de energías renovables, alineándose con nuestra visión de un futuro más verde para Latinoamérica.
                        </p>                          
                    </div>
                    <div class="services-img d-flex justify-content-center">
                        <img src="/assets/img/Compromiso con la sostenibilidad.svg" height="250" width="auto" alt="services-img">
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
