import { ChangeDetectorRef, Component } from '@angular/core';
import {FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ContactService } from '../../services/contact.service';
import { ConfirmFormComponent } from '../confirm-form/confirm-form.component';

@Component({
  selector: 'app-project-to-quote',
  templateUrl: './project-to-quote.component.html',
  styleUrls: ['./project-to-quote.component.scss']
})
export class ProjectToQuoteComponent {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  finalFormGroup: FormGroup;
  public countries: any[] = []
  public fileSelectedSupport: any;
  public fileSelectedSupportName: any;
  public fileSelectedNda: any;
  public fileSelectedNdaName: any;
  public sending: boolean = false;

  constructor(private _formBuilder: FormBuilder,private dialog: MatDialog,public contactService: ContactService, private cd: ChangeDetectorRef) {
    this.firstFormGroup = this._formBuilder.group({
      tipo:['Project to Quote'],
      project_title: ['', Validators.required],
      estigmated_energisation_date: ['', Validators.required],
      project_location: ['', Validators.required]

    });
    this.secondFormGroup = this._formBuilder.group({
      required_power: ['', Validators.required],
      required_energy: ['', Validators.required],
      project_purpose: ['', Validators.required],
      project_lifetime: ['', Validators.required],
      number_cycles_per_day: ['', Validators.required],
      on_grid_off_grid: ['', Validators.required],
      coupling_solar_pv: ['', Validators.required],
      voltage_level: ['', Validators.required],
      coupled_generation: ['', Validators.required],
      ems: ['', Validators.required],
      primary_application: [[], Validators.required],
      comment:['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      company_name: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      phone_number: [''],
      address: ['', Validators.required],
      zip_code: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
    });

    this.finalFormGroup = this._formBuilder.group({
      standard_nda: ['', Validators.required],
      supporting_documents: [''],
      nda: ['']
    });

  }

  ngOnInit(): void {
    this.contactService.getCountries().subscribe({
      next: (r: any) => {
        this.countries = r.message
      }
    })
  }

  submit(): void {
    if(this.finalFormGroup.invalid){
      return;
    }

    this.sending = true;
    let formularioFinal = Object.assign( this.firstFormGroup.value , this.secondFormGroup.value , this.thirdFormGroup.value)

    const date: Date = formularioFinal.estigmated_energisation_date;

    // Convertir a formato aaaa-mm-dd
    const formattedDate = date.toISOString().split('T')[0];

    formularioFinal.estigmated_energisation_date = formattedDate
    console.log(formularioFinal)

    this.contactService.submitInquiry(formularioFinal).subscribe({
      next: (r: any) => {
        if(this.fileSelectedSupport) {
          let reader = new FileReader();
          reader.readAsDataURL(this.fileSelectedSupport);
          reader.onload = () => {
            let file = {
              "doc_name": r.message.name,
              "supporting_documents": String(reader.result),
              "file_name": this.fileSelectedSupportName
            }

            console.log(file);
            this.contactService.uploadSupportFile(file).subscribe({
              next: (r: any) => {
                if(this.fileSelectedNda) {
                  let reader = new FileReader();
                  reader.readAsDataURL(this.fileSelectedNda);
                  reader.onload = () => {
                    let file = {
                      "doc_name": r.message,
                      "nda": String(reader.result),
                      "file_name": this.fileSelectedNdaName
                    }
                    console.log(file);
                    this.contactService.uploadNdaFile(file).subscribe({
                      next: (r: any) => {
                        
                      },
                    })
                  }
                }
              },
            })
          }
        }else{
          if(this.fileSelectedNda) {
            let reader = new FileReader();
            reader.readAsDataURL(this.fileSelectedNda);
            reader.onload = () => {
              let file = {
                "doc_name": r.message.name,
                "nda": String(reader.result),
                "file_name": this.fileSelectedNdaName
              }
              console.log(file);
              this.contactService.uploadNdaFile(file).subscribe({
                next: (r: any) => {
                  
                },
              })
            }
          }
        }
          
        this.sending = false;
        this.dialog.open(ConfirmFormComponent, { disableClose: true });
        this.cd.detectChanges();
      },
      error: (e) => {
        this.sending = false;
        console.log(e);
      }
    })

  }

  fileSelectionSupportChanged(event: any) {
    const [file] = event.target.files;
    const fileListAsArray = Array.from(event.target.files);
    fileListAsArray.forEach((item: any, i) => {
      this.fileSelectedSupport = item;
      this.fileSelectedSupportName = item.name;
    });

    this.finalFormGroup.patchValue({
      supporting_documents: this.fileSelectedSupport,
    });
  }

  fileSelectionNdaChanged(event: any) {
    const [file] = event.target.files;
    const fileListAsArray = Array.from(event.target.files);
    fileListAsArray.forEach((item: any, i) => {
      this.fileSelectedNda = item;
      this.fileSelectedNdaName = item.name;
    });

    this.finalFormGroup.patchValue({
      nada: this.fileSelectedNda,
    });
  }
  
}
