
<main style="background-color: white;">
    <div style="padding-left: 10%;margin-bottom: 5dvh;padding-top: 10%;">
        <h2><b>OTRAS CONSULTAS</b></h2>
    </div>
    <div style="padding-left: 10%;">
        <h3><b>FORMULARIO DE CONSULTA</b></h3>
        <form [formGroup]="firstFormGroup" (ngSubmit)="submit()">
            <div class="row mt-4">
                <div class="col-4">
                    <mat-label class="mb-2"><b>Nombre *</b></mat-label>
                <mat-form-field appearance="outline" class="mt-2">
                    <input matInput placeholder="Nombre" formControlName="first_name" />
                </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-label class="mb-2"><b>Apellido *</b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="Escriba su apellido" formControlName="last_name" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <mat-label class="mb-2"><b>Correo electrónico *</b></mat-label>
                <mat-form-field appearance="outline" class="mt-2">
                    <input matInput placeholder="Escriba su correo electrónico" formControlName="email" />
                </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-label class="mb-2"><b>Número de teléfono *</b></mat-label>
                <mat-form-field appearance="outline" class="mt-2">
                    <input matInput placeholder="Escriba su número de teléfono" formControlName="phone_number" />
                </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <p class="mb-2"><b>CONSULTA *</b></p>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <textarea matInput placeholder="Escriba aquí su mensaje..." formControlName="inquiry"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-10 text-end">
                    <button type="submit" class="btn btn-dark" ><b>ENVIAR CONSULTA</b></button>
                </div>
            </div>
        </form>
    </div>
</main>