<div class="main-banner pt-70">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1 class="pc-font-size-mt mobile-font-size">The online service center <br> will be available soon.</h1>
                    <p style="color:#EFE6C0; font-size:large">Contact</p>
                    <div class="col-lg-12 col-md-12 ">
                        <button type="button" class="btn btn-second">
                            <a href="mailto:service@collective-energy.africa">
                                service@collective-energy.africa
                            </a></button>                    </div>
                            <p style="color:#3A9B43; font-size:large" (click)="copyText('+254 207 640 823')">+254 207 640 823</p><br><br>
                        </div>
            </div>
        </div>
    </div>
</div>