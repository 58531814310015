import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, FormControl, Validators, UntypedFormGroup, UntypedFormArray, FormBuilder } from '@angular/forms';
import { ContactFormService } from 'src/app/contact-form.service';
import { ClientService } from 'src/app/client.service';

declare const google: any;

@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrls: ['./home-five.component.scss']
})
export class HomeFiveComponent implements OnInit {

  showInfoBox = false;
  map: any;
  markers = [];
  public clientInfo: { [key: string]: string } = {};

  currentSection: string = '';

  public formularioEnviado = false;
  public formLoading = false;
  public isSubmitted = false;


   contactForm  = this.formBuilder.group({
      first_name:['', Validators.required],
      surname:['', [Validators.required]], 
      company_name:['', Validators.required], 
      email: ['', [Validators.required, Validators.email]],
      message:['', Validators.required], 
  });
  
  ngForm: any;

  selectedClient: any;

  // Método para cambiar la clase de la sección actual
  changeSection(section: string) {
    this.currentSection = section;
    console.log(this.currentSection)
  }
  copyText(textToCopy: string) {
    const tempInput = document.createElement('input');
    tempInput.setAttribute('value', textToCopy);
    document.body.appendChild(tempInput);
    
    tempInput.select();
    document.execCommand('copy');
    
    document.body.removeChild(tempInput);
    
    alert('Texto copiado: ' + textToCopy);
  }
  constructor(
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private router: Router,
    public formBuilder: FormBuilder,
    private contactService: ContactFormService,
      private clientService: ClientService
  ) { }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    // this.getClients();
    this.initMap();
    this.route.queryParamMap.subscribe(queryParams => {
      this.currentSection = queryParams.get('section') || ''; // If no section query parameter, set it to an empty string
      this.highlightActiveSection(this.currentSection);
    });
    this.updateInfoBoxContent();
  }
  iconos = {
    iconoNormal: "/assets/img/Location (1).ico",
    iconoSeleccionado: "/assets/img/SelectedLocation.ico"
  };
  initMap() {
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 4,
      center: { lat: 1.3751339650443164, lng: 32.4574814527082 },
      panControl: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      gestureHandling: "cooperative",
      rotateControl: false,
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [
            {
              saturation: -100, // Desaturación para convertir los colores a blanco y gris
            },
            {
              lightness: 40, // Ajusta este valor para controlar el nivel de brillo
            },
          ],
        },
      ],

    });

    this.clientService.getClients().subscribe((response: any) => {
      const clients = response.data;
      console.log(clients);

      let activeMarker: google.maps.Marker | null = null;

      clients.forEach((client: any) => {

        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(client.latitud), lng: parseFloat(client.longitud)
          },
          map: map,
          icon: "/assets/img/distribute_strokeok.png",


        });
        this.markers.push(marker)

        const secretMessages = `
        <strong style="font-size: 8px;">${client.nombre}</strong><br>
        <span style="font-size: 8px;">${client.direccion}</span><br>
        <span style="font-size: 8px;">${client.web}</span><br>
        <span style="font-size: 8px;">${client.email}</span><br>
        <span style="font-size: 8px;">${client.telefono}</span><br>
      `;
        const infowindow = new google.maps.InfoWindow({
          content: secretMessages,
        });

        marker.addListener("click", () => {
          this.selectedClient = client;
          infoBox.style.display = "block"; // Mostrar el recuadro fijo
          this.updateInfoBoxContent();
          if (activeMarker) {
            activeMarker.setIcon("/assets/img/distribute_strokeok.png");
          }
          marker.setIcon("/assets/img/distribute_stroke.png");
          activeMarker = marker;
          
        });
        this.markers.push(marker);

      });
      console.log(this.markers)
    });

    // Crear recuadro fijo
    const infoBox = document.createElement("div");
    infoBox.id = "infoBox";
    infoBox.style.position = "absolute";
    infoBox.style.display = "none";
    infoBox.style.backgroundColor = "white";
    infoBox.style.padding = "10px";
    infoBox.style.border = "none";
    infoBox.style.zIndex = "1";
    infoBox.innerHTML = `

    `;
    document.getElementById("map").appendChild(infoBox);

    infoBox.addEventListener("click", () => {
      // Acción al hacer clic en el recuadro fijo
    });
    const marker = new google.maps.Marker({
      position: { lat:-1.3226231083810491, lng: 36.86874787893245 },
      map: map, 
      url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
  });
  const marker2 = new google.maps.Marker({
    position: { lat:-1.3147094750291, lng: 36.858508855129465 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker3 = new google.maps.Marker({
    position: { lat:-1.2175399705080838, lng: 36.99048166649185 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker4 = new google.maps.Marker({
    position: { lat: -1.26970329278827, lng: 36.80710698026305 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker5 = new google.maps.Marker({
    position: { lat:-6.84637874190159, lng: 39.23978008001635 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker6 = new google.maps.Marker({
    position: { lat:-6.761314908315011, lng: 39.227448997667324 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker7 = new google.maps.Marker({
    position: { lat:-6.846453308220915, lng: 39.23978008001636 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker8 = new google.maps.Marker({
    position: { lat:4.8560369794023535, lng: 31.578068326397823 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker9 = new google.maps.Marker({
    position: { lat:-15.450147244999247, lng: 28.268267724329707 },
    map: map, 
    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" 
});
  const marker10 = new google.maps.Marker({
    position: { lat:-1.2992198771440937, lng: 36.783535810671324 },
    map: map, 
    icon: "/assets/img/distribute_strokeok.png",
  });
  const marker11 = new google.maps.Marker({
    position: { lat:-26.264774379114577, lng: 28.024476614175395 },
    map: map, 
    icon: "/assets/img/distribute_strokeok.png",

    
})
const infoWindow1 = new google.maps.InfoWindow({
  content: "<h1> Chloride Exide Kenya </h1><p>Exsan house Enterprise road, industrial area Enterprise Rd, Nairobi.</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker.addListener("click", () => {
  infoWindow1.open(map, marker);
});
const infoWindow2 = new google.maps.InfoWindow({
  content: "<h1> Nabico Enterprises ltd </h1><p>74 Enterprise Rd, Nairobi.</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker2.addListener("click", () => {
  infoWindow2.open(map, marker2);
});
const infoWindow3 = new google.maps.InfoWindow({
  content: "<h1> Gogreen Energy ltd </h1><p>Eastern Bypass, Nairobi.</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker3.addListener("click", () => {
  infoWindow3.open(map, marker3);
});
const infoWindow4 = new google.maps.InfoWindow({
  content: "<h1> Cytek Solar Kenya </h1><p>The Mirage towers, Nairobi-Westlands.</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker4.addListener("click", () => {
  infoWindow4.open(map, marker4);
});
const infoWindow5 = new google.maps.InfoWindow({
  content: "<h1> AG Energies ltd </h1><p>Julius K. Nyerere Rd.</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker5.addListener("click", () => {
  infoWindow5.open(map, marker5);
});
const infoWindow6 = new google.maps.InfoWindow({
  content: "<h1> Photons Energy ltd </h1><p>Opposite Suma JKT Along Namanga Road City 16158.</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker6.addListener("click", () => {
  infoWindow6.open(map, marker6);
});
const infoWindow7 = new google.maps.InfoWindow({
  content: "<h1> Photons Energy ltd </h1><p>Julius K. Nyerere Rd.</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker7.addListener("click", () => {
  infoWindow7.open(map, marker7);
});
const infoWindow8 = new google.maps.InfoWindow({
  content: "<h1> Zetin Solar </h1><p>VH4H+86F, Gudele Rd, Juba, South Sudan</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker8.addListener("click", () => {
  infoWindow8.open(map, marker8);
});
const infoWindow9 = new google.maps.InfoWindow({
  content: "<h1> Blue Planet Solar </h1><p>397a Kafue Rd, Lusaka, Zambia</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker9.addListener("click", () => {
  infoWindow9.open(map, marker9);
});
const infoWindow10 = new google.maps.InfoWindow({
  content: "<h1> Collective Energy South Africa </h1><p>6th floor Morningside Office Park, Kilimani, Nairobi, Kenya</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker10.addListener("click", () => {
  infoWindow10.open(map, marker10);
});
const infoWindow11 = new google.maps.InfoWindow({
  content: "<h1> Collective Energy South Africa </h1><p>15 Ivor street, Gillview, Johannesburg, 2091</p>",
});

// Asocia el InfoWindow al marcador cuando se hace clic
marker11.addListener("click", () => {
  infoWindow11.open(map, marker11);
});
  }

  highlightActiveSection(sectionId: string): void {
    const navLinks = document.querySelectorAll('.navbar-nav .link-btn');
    navLinks.forEach(link => {
      link.classList.remove('active');
      const routerLink = link.getAttribute('href');
      if (routerLink && routerLink === `/${sectionId}`) {
        link.classList.add('active');
      }
    });
  }

  submit() {
    this.formLoading = true;
    this.isSubmitted = true;
    if (this.contactForm.invalid) {
      console.log(this.contactForm);
      this.formLoading = false;
      return;
    }

    this.contactService.sendForm(this.contactForm.value).subscribe({
      next: (r: any) => {
        console.log(r);
        this.formularioEnviado = true;
        this.formLoading = false;
      },
      error: (e) => console.log(e)
    });
  }

  updateInfoBoxContent() {
    const infoBox = document.getElementById("infoBox");
    if (infoBox && this.selectedClient) {
      infoBox.innerHTML = `
      <p style="font-size: 14px;"><strong style="font-size: 16px;">${this.selectedClient.nombre}</strong></p>
      ${this.selectedClient.direccion !== null ? `
        <div>
          <p style="font-size: 14px;"><strong style="font-size: 16px;"></strong>${this.selectedClient.direccion}</p>
        </div>
      ` : ''}
      ${this.selectedClient.web !== null ? `
        <div>
        <a style="font-size: 14px;" href="http://${this.selectedClient.web}" target="_blank">
        <strong style="font-size: 16px;">
          <span style="color:#3A9B43;">${this.selectedClient.web}</span>
        </strong>
      </a>
      
      
        </div>
        ` : ''}
        ${this.selectedClient.email !== null ? `
        <div>
        <p style="font-size: 14px;"><strong style="font-size: 16px;"></strong> ${this.selectedClient.email}</p>
        </div>
        ` : ''}
        ${this.selectedClient.email !== null ? `
        <div>
        <p style="font-size: 14px;"><strong style="font-size: 16px;"></strong> ${this.selectedClient.telefono}</p>
        </div>
        ` : ''}

      `;
    }
  }

  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 22.2736308,
    lng: 70.7512555
  };
  zoom = 6;

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

}
