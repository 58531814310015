<main style="background-color: white;">
    <div style="padding-top: 10%;padding-left: 10%;">
        <h2>SOY UN CLIENTE FINAL INTERESADO EN BATERÍAS</h2>
    
    <mat-stepper labelPosition="bottom" #stepper linear="true" >
        <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepperIcon="edit">
                <mat-icon>✓</mat-icon>
              </ng-template><ng-template matStepLabel>INFORMACIÓN DEL SISTEMA</ng-template>
            <div class="mt-5 mb-4" style="color:black">
                <h2><b>INFORMACIÓN DEL SISTEMA</b></h2>
            </div>
            <h4 style="margin: 32px 0 16px;" class="mb-4 mt-4"><b>UBICACIÓN</b></h4>
            <div class="row">
                <div class="col-8">
                    <mat-label class="d-block mb-2"><b>Dirección *</b></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="Escriba su dirección" formControlName="address" required />
                    </mat-form-field>
                </div>
                
            </div>
              <div class="row">
                <div class="col-3">
                    <mat-label class="d-block mb-2"><b>Código postal *</b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="Escriba su código postal" formControlName="zip_code" required />
                      </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-label class="d-block mb-2"><b>Ciudad *</b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="Escriba su ciudad" formControlName="city" required />
                      </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-label class="d-block mb-2"><b>País *</b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <mat-select formControlName="country" required>
                            <mat-option *ngFor="let country of countries" value="{{ country.name }}">{{country.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
              </div>

            <div>
            <div class="row">
                <div class="col-10">

                </div>
                <div class="col-2">
                    <button mat-button matStepperNext>
                        <img src="/assets/img/next.png" alt="">
                    </button>
                </div>
            </div>
            
            </div>
        </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>DATOS ENERGÉTICOS</ng-template>
            <div>
                <div>
                    <h4 style="margin: 32px 0 16px;" class="mb-4 mt-4"><b>INFORMACIÓN ENERGÉTICA</b></h4>
                  </div>

                  <div class="row">
                    <div class="col-4">
                        <mat-label class="mb-2"><b>Consumo medio mensual de energía *</b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input type="number" matInput placeholder="Introduzca el consumo de energía" formControlName="average_energy_consumption" required />
                            <span style="margin-right:5px;" matSuffix>kWh</span>
                          </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-label class="mb-2"><b>Capacidad deseada del sistema</b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input type="number" matInput placeholder="Introduzca la capacidad del sistema" formControlName="desired_capacity" />
                            <span style="margin-right:5px;" matSuffix>kWh</span>
                          </mat-form-field>
                    </div>
                  </div>
            
                  <div class="row">
                    <div class="col-3">
                        <mat-label class="mb-2"><b>Calendario de instalación</b></mat-label>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="installation_timeline">
                                <mat-option value="ASAP">ASAP</mat-option>
                                <mat-option value="Within 6 months">En 6 meses</mat-option>
                                <mat-option value="12 months">Dentro de 12 meses</mat-option>
                                <mat-option value="Later/unkown">Más tarde</mat-option>
                                
                            </mat-select>
                        </mat-form-field>
                    </div>
                  </div>
                  
                <div class="mt-5 mb-4" style="color:black">
                    <h2><b>DATOS ENERGÉTICOS</b></h2>
                </div>

                <div class="row mb-4">
                    <div class="col-4">
                        <p class="mb-2"><b>¿Dispone de una fuente de generación de energía??</b></p>
                        <mat-radio-group formControlName="have_energy_source" (change)="onEnergySourceChange($event.value)">
                            <mat-radio-button class="me-4" value="Yes">Si</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-4" *ngIf="showInstalledPower">
                        <p class="mb-2"><b>Especifique la potencia instalada</b></p>
                        <mat-form-field appearance="outline">
                            <input matInput type="number" formControlName="installed_power">
                            <span style="margin-right:5px;" matSuffix>kW</span>
                        </mat-form-field>
                    </div>
                    <div class="col-4" *ngIf="showInstalledPower">
                        
                    </div>
                    <div class="col-4">
                        <p class="mb-2"><b>Specify coupling type</b></p>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="coupling_type">
                                <mat-option value="AC coupling">Acoplamiento CA</mat-option>
                                <mat-option value="DC coupling">Acoplamiento CC</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4"  *ngIf="!showInstalledPower"></div>
                    <div class="col-4">
                        <p class="mb-2"><b>Aplicación principal</b></p>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="primary_application" multiple>
                                <mat-option value="Backup">Copia de seguridad</mat-option>
                                <mat-option value="Avoid grid upgrades">Evitar actualizaciones de la red</mat-option>
                                <mat-option value="Peak Shaving">Reducción de picos</mat-option>
                                <mat-option value="Self-consumption">Autoconsumo</mat-option>
                                <mat-option value="Arbitrage / Time shifting">Arbitraje / Time shifting</mat-option>
                                <mat-option value="Grid services">Servicios de red</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4"  *ngIf="showInstalledPower"></div>
                    <div class="col-4">
                        <p class="mb-2"><b>En red o sin red*</b></p>
                        <mat-radio-group formControlName="on_grid_off_grid">
                            <mat-radio-button class="me-4" value="On-grid">En la red</mat-radio-button>
                            <mat-radio-button class="me-4" value="Off-grid">Sin conexión a la red</mat-radio-button>
                            <mat-radio-button value="On-Grid+Backup">En red+copia de seguridad</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="row">
                    <div>
                        <h4 style="margin: 32px 0 16px;" class="mb-4 mt-4"><b>DETALLES DE CONSUMO</b></h4>
                    </div>

                    <div class="row mb-2">
                        <div class="col-3">
                            <mat-label><b>Tarifa energética media</b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2" >
                                <input matInput type="number" placeholder="Introduzca el consumo de energía" formControlName="energy_consumption" />
                                <span style="margin-right:5px;" matSuffix>€/kWh</span>
                              </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-4">
                            <mat-label><b>Importación máxima permitida (desde la red)</b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2" >
                                <input matInput type="number" placeholder="Enter allowed import" formControlName="allowed_import" />
                                <span style="margin-right:5px;" matSuffix>kW</span>
                              </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-label><b>Exportación máxima permitida (desde la red)</b></mat-label>
                            <mat-form-field appearance="outline" class="mt-2" >
                                <input matInput type="number" placeholder="Introducir exportación permitida" formControlName="allowed_export" />
                                <span style="margin-right:5px;" matSuffix>kW</span>
                              </mat-form-field>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-md-12 col-sm-12 col-lg-6">
                            <div class="row" style="background-color: #f2f2f2; align-items: center; padding: 8px;">
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <mat-label><b>Datos de consumo</b></mat-label>
                                    <p>Cargar el archivo de consumo cada media hora del último año completo.</p>
                                </div>
                                <div class="col-md-12 col-sm-12 col-lg-6">
                                    <input class="form-control" type="file" id="formFile" formControlName="end" (change)="fileSelectionChanged($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="d-block col-6">
                            <mat-label><b>Otros comentarios</b></mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <textarea matInput formControlName="comment"></textarea>
                              </mat-form-field>
                        </div>
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-10">
                    </div>
                    <div class="col-2">
                        <button mat-button matStepperPrevious>
                            <img src="/assets/img/back.png" alt="">
                        </button>
                        <button mat-button matStepperNext>
                            <img src="/assets/img/next.png" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup">
            <form [formGroup]="thirdFormGroup">
                <ng-template matStepLabel>INFORMACIÓN AL CLIENTE</ng-template>
                <div>
                    <div class="mt-5 mb-4" style="color:black">
                        <h2><b>INFORMACIÓN AL CLIENTE</b></h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <mat-label class="d-block mb-2"><b>Nombre *</b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="Enter name" formControlName="first_name" />
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-label class="d-block mb-2"><b>Apellido*</b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="Escriba su apellido" formControlName="last_name" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-label class="d-block mb-2"><b>Correo electrónico *</b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="Escriba su correo electrónico" formControlName="email" />
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-label class="d-block mb-2"><b>Número de teléfono *</b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="Escriba su número de teléfono" formControlName="phone_number" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-7">
                    </div>
                    <div class="col-2 text-end">
                        <button mat-button matStepperPrevious>
                            <img src="/assets/img/back.png" alt="">
                        </button>
                    </div>
                    <div class="col-3">
                        <button mat-button matStepperNext>
                            <button type="button" (click)="submit()" class="btn btn-dark"><b>SOLICITAR MÁS INFORMACIÓN</b></button>
                        </button>
                    </div>
                </div>
            </form>
        </mat-step>
    </mat-stepper>
</div>
</main>
