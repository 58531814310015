<main style="background-color: #f2f2f2;padding-top: 10%;">
    <div style="padding-left: 10%;">
        <h2 [innerHTML]="'TENGO UN PROYECTO PARA COTIZAR'"></h2>
    
    <mat-stepper labelPosition="bottom" #stepper linear="true" >
        <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepperIcon="edit">
                <mat-icon>✓</mat-icon>
              </ng-template>
              <ng-template matStepLabel><span>
                DETALLES DEL PROYECTO
              </span></ng-template>
            <div class="mt-5 mb-4" style="color:black">
                <h2><b [innerHTML]="'DETALLES DEL PROYECTO'"></b></h2>
            </div>
            <div class="row">
                <div class="col-4">
                    <mat-label class="mb-2"><b [innerHTML]="'Título del proyecto *'"></b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="{{'Introduzca su título' }}" formControlName="project_title" required />
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-label><b>Fecha estimada de energización *</b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput [matDatepicker]="picker" formControlName="estigmated_energisation_date" required>
                        <mat-hint>MM/DD/AAAA</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                </div>
            </div>
              <div class="row">
                <div class="col-4">
                    <mat-label class="mb-2"><b [innerHTML]="'Ubicación del proyecto *' "></b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="{{'Introduzca la ubicación'}}" formControlName="project_location" required />
                      </mat-form-field>
                </div>
              </div>
            <div>
            <div class="row">
                <div class="col-10">

                </div>
                <div class="col-2">
                    <button mat-button matStepperNext>
                        <img src="/assets/img/next.png" alt="">
                    </button>
                </div>
            </div>
            
            </div>
        </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel [innerHTML]="'ESPECIFICACIONES TÉCNICAS' "></ng-template>
            <div>
                <div class="mt-5 mb-4" style="color:black">
                    <h2><b [innerHTML]="'ESPECIFICACIONES TÉCNICAS' "></b></h2>
                </div>

                <div class="row">
                    <div class="col-3">
                        <mat-label class="mb-2"><b [innerHTML]="'Potencia necesaria *'"></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Introducir potencia'}}" formControlName="required_power" required />
                            <span style="margin-right:5px;" matSuffix>kW</span>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                            <mat-label class="mb-2"><b [innerHTML]="'Energía necesaria *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Introducir energía' }}" formControlName="required_energy" required />
                            <span style="margin-right:5px;" matSuffix>kWh</span>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <p class="mb-1"><b>Medido en *</b></p>
                        <mat-form-field appearance="outline"  >
                            <mat-select formControlName="project_purpose">
                                <mat-option value="Beginning of Life (BOL)">
                                    {{"Inicio de la vida (BOL)"}}
                                </mat-option>
                                <mat-option value="End of Life (EOL)">
                                    {{"Fin de vida (EOL)"}}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3">
                        <p class="mb-2"><b [innerHTML]="'Duración del proyecto *'"></b></p>
                        <mat-form-field appearance="outline"  >
                            <mat-select formControlName="project_lifetime">
                                <mat-option value="5 years">
                                    {{"5 años"}}
                                </mat-option>
                                <mat-option value="10 years">
                                    {{"10 años"}}
                                </mat-option>
                                <mat-option value="15 years">
                                    {{"15 años"}}
                                </mat-option>
                                <mat-option value="20 years">
                                    {{"20 años"}}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-3">
                        <p><b [innerHTML]="'Número de ciclos por día *' "></b></p>
                        <mat-radio-group formControlName="number_cycles_per_day" >
                            <mat-radio-button class="me-2" style="margin-left: 16px;" value="1 cycles">
                                {{"<=1 ciclos" }}
                            </mat-radio-button>
                            <mat-radio-button value="1.5 cycles" >
                                {{"1.5 ciclos" }}
                            </mat-radio-button> <br>
                            <mat-radio-button class="me-2" value="2 cycles">
                                {{"2 ciclos" }}
                            </mat-radio-button>
                            <mat-radio-button value="2.5 cycles">
                                {{"2.5=< ciclos" }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-3">
                        <p class="mb-2"><b>
                            {{"Con o sin conexión a la red" }} *</b></p>
                        <mat-radio-group formControlName="on_grid_off_grid" >
                            <mat-radio-button class="me-2" value="On-grid" >
                                {{"En la red" }}
                            </mat-radio-button>
                            <mat-radio-button value="Off-grid" >
                                {{"Sin conexión a la red" }}
                            </mat-radio-button><br>
                            <mat-radio-button style="margin-left: 0;" value="">
                                {{"En red+copia de seguridad" }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-3">
                        <p class="mb-2"><b>Junto con la generación *</b></p>
                        <mat-form-field appearance="outline"  >
                            <mat-select formControlName="coupled_generation" multiple>
                                <mat-option value="None">
                                    {{"Ninguno" }}
                                </mat-option>
                                <mat-option value="Solar PV">
                                    {{"Solar PV" }}
                                </mat-option>
                                <mat-option value="Wind">
                                    {{"Wind" }}
                                </mat-option>
                                <mat-option value="Genset">
                                    {{"Genset" }}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <div class="col-3">
                        <p class="mb-2"><b [innerHTML]="'Acoplamiento de CA o CC para energía solar fotovoltaica*' "></b></p>
                        <mat-radio-group formControlName="coupling_solar_pv">
                            <mat-radio-button value="AC coupling">
                                {{"Acoplamiento de CA (independiente o hibridación CA-CC)" }}
                            </mat-radio-button><br>
                            <mat-radio-button style="margin-left: 0;" value="DC coupling">
                                {{"Acoplamiento CC (hibridación CC-CC, sin carga de red)'" }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3">
                        <p class="mb-2"><b [innerHTML]="'AC nivel de tensión de PoC*' "></b></p>
                        <mat-form-field appearance="outline" >
                            <mat-select formControlName="voltage_level">
                                <mat-option value="Low Voltage">
                                    {{"Baja tensión (LV): 400V" }}
                                </mat-option>
                                <mat-option value="Medium Voltage">
                                    {{"Media tensión (MV): 6,6kV - 35kV" }}
                                </mat-option>
                                <mat-option value="Others">
                                    {{"Otros" }}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <div class="col-3">
                        <p class="mb-2"><b [innerHTML]="'Sistema de gestión de energía (EMS)' "></b></p>
                        <mat-form-field appearance="outline" >
                            <mat-select formControlName="ems">
                                <mat-option value="Yes">
                                    {{"Si" }}
                                </mat-option>
                                <mat-option value="No">
                                    {{"No" }}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-3">
                        <p class="mb-2"><b [innerHTML]="'Aplicación principal*' "></b></p>
                        <mat-form-field appearance="outline" >
                            <mat-select formControlName="primary_application" multiple>
                                <mat-option value="Backup">
                                    {{"Respaldo" }}
                                </mat-option>
                                <mat-option value="Avoid grid upgrades">
                                    {{"Evitar actualizaciones de la red" }}
                                </mat-option>
                                <mat-option value="Peak Shaving">
                                    {{"reducción de picos" }}
                                </mat-option>
                                <mat-option value="Self-consumtion">
                                    {{"autoconsumo" }}
                                </mat-option>
                                <mat-option value="Arbitrage / Time shifting">
                                    {{"Arbitraje / Time-shifting" }}
                                </mat-option>
                                <mat-option value="Grid services" >
                                    {{"Servicios de red" }}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <mat-label><b [innerHTML]="'Otros comentarios*' "></b></mat-label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput formControlName="comment"></textarea>
                          </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-10">
                    </div>
                    <div class="col-2">
                        <button mat-button matStepperPrevious>
                            <img src="/assets/img/back.png" alt="">
                        </button>
                        <button mat-button matStepperNext>
                            <img src="/assets/img/next.png" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup">
            <form [formGroup]="thirdFormGroup">
                <ng-template matStepLabel [innerHTML]="'Información de la compañía' "></ng-template>
                <div>
                    <div class="mt-5 mb-4" style="color:black">
                        <h2><b [innerHTML]="'Información de la compañía' "></b></h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <mat-label class="mb-2"><b [innerHTML]="'Nombre de la compañía *' "></b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="{{'Nombre' }}" formControlName="company_name" />
                      </mat-form-field>
                    </div>
                </div>

                <h4 style="margin: 32px 0 16px;" class="mb-4 mt-4"><b [innerHTML]="'PERSONA DE CONTACTO DE LA EMPRESA' "></b></h4>
                <div class="row">
                    <div class="col-4">
                        <mat-label class="mb-2"><b [innerHTML]="'Nombre *' "></b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="{{'Escriba su nombre' }}" formControlName="first_name" />
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-label class="mb-2"><b [innerHTML]="'Apellido *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Escriba su apellido' }}" formControlName="last_name" />
                          </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <mat-label class="mb-2"><b [innerHTML]="'Correo electrónico *' "></b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="{{'Escriba su correo electrónico' }}" formControlName="email" />
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-label class="mb-2"><b [innerHTML]="'Número de teléfono *' "></b></mat-label>
                    <mat-form-field appearance="outline" class="mt-2">
                        <input matInput placeholder="{{'Escriba su número de teléfono' }}" formControlName="phone_number" />
                      </mat-form-field>
                    </div>
                </div>

                <h4 style="margin: 32px 0 16px;" class="mb-4 mt-4"><b [innerHTML]="'Dirección de la compañía' "></b></h4>
                <div class="row">
                    <div class="col-8">
                        <mat-label class="mb-2"><b [innerHTML]="'Dirección *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Escriba su dirección' }}" formControlName="address" required />
                        </mat-form-field>
                    </div>
                </div>
                  <div class="row">
                    <div class="col-2">
                        <mat-label class="mb-2"><b [innerHTML]="'Código postal *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Escriba su código postal' }}" formControlName="zip_code" required />
                          </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-label class="mb-2"><b [innerHTML]="'Ciudad *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <input matInput placeholder="{{'Escriba su ciudad' }}" formControlName="city" required />
                          </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-label class="mb-2"><b [innerHTML]="'País *' "></b></mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                        <mat-select formControlName="country" required>
                            <mat-option *ngFor="let country of countries" value="{{ country.name }}">{{country.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                  </div>
                <div class="row">
                    <div class="col-10">
                    </div>
                    <div class="col-2">
                        <button mat-button matStepperPrevious>
                            <img src="/assets/img/back.png" alt="">
                        </button>
                        <button mat-button matStepperNext>
                            <img src="/assets/img/next.png" alt="">
                        </button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="finalFormGroup">
            <form [formGroup]="finalFormGroup">
                <ng-template matStepLabel [innerHTML]="'SUBMIT' "></ng-template>
                <div>
                    <div class="mt-5 mb-4" style="color:black">
                        <h2><b [innerHTML]="'ENVIAR' "></b></h2>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <div class="row" style="background-color: #f2f2f2; align-items: center; padding: 8px;">
                            <div class="col-md-12 col-sm-12 col-lg-6">
                                <mat-label><b [innerHTML]="'Cargar documentos de respaldo' "></b></mat-label>
                                <p [innerHTML]="'Cargar documentos de respaldo (por ejemplo, documentos de licitación, dibujos técnicos)' "></p>
                            </div>
                            <div class="col-md-12 col-sm-12 col-lg-6">
                                <input class="form-control" type="file" id="formFile" formControlName="supporting_documents" (change)="fileSelectionSupportChanged($event)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <mat-label class="mb-2"><b [innerHTML]="'Acuerdo de confidencialidad estándar *' "></b></mat-label><br>
                        <mat-checkbox class="example-margin" formControlName="standard_nda">
                            {{ 'He leído el NDA y acepto los'  }}
                            <a href="/assets/pdf/oneNDA - EFT Systems.docx.pdf" target="_blank">
                              {{ 'Terminos'  }}
                            </a>.
                          </mat-checkbox>
                          
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-12 col-sm-12 col-lg-6">
                        <div class="row" style="background-color: #f2f2f2; align-items: center; padding: 8px;">
                            <div class="col-md-12 col-sm-12 col-lg-6">
                                <mat-label><b [innerHTML]="'Cargar NDA' "></b></mat-label>
                                <p [innerHTML]="'Si es necesario, cargue su propio NDA para su revisión.' "></p>
                            </div>
                            <div class="col-md-12 col-sm-12 col-lg-6">
                                <input class="form-control" type="file" id="formFile" formControlName="nda" (change)="fileSelectionNdaChanged($event)">
                            </div>
                        </div>
                    </div>                        
                </div>

                <div class="row">
                    <div class="col-md-12 col-sm-12 col-lg-7">
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-2 text-end">
                        <button mat-button matStepperPrevious>
                            <img src="/assets/img/back.png" alt="">
                        </button>
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-3">
                        <button mat-button matStepperNext>
                            <button type="button" (click)="submit()" class="btn btn-dark" [disabled]="sending" [innerHTML]="'ENVIAR SOLICITUD DE PRESUPUESTO'"><b></b></button>
                        </button>
                    </div>
                </div>
            </form>
        </mat-step>
    </mat-stepper>
</div>
</main>