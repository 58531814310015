import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/BydBatteryBox/home-one.component';
import { HomeTwoComponent } from './components/RaiOn/home-two.component';
import { HomeThreeComponent } from './components/AboutUs/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/WhereToBuy/home-five.component';
import { HomeSixComponent } from './components/Contact/home-six.component';
import { HomeSevenComponent } from './components/Home/home-seven.component';
import { HomeEightComponent } from './components/Download/home-eight.component';
import { HomeNineComponent } from './components/OnlineServiceCenter/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { ServicioComponent } from './components/servicio/servicio.component';
import { ChargingComponent } from './components/charging/charging.component';
import { OtherInquiriesComponent } from './components/other-inquiries/other-inquiries.component';
import { CustomerInterestedComponent } from './components/customer-interested/customer-interested.component';
import { ProjectToQuoteComponent } from './components/project-to-quote/project-to-quote.component';

const routes: Routes = [
    {path: '', component: HomeSevenComponent},
    { path: 'byd-betterybox', component: HomeOneComponent },
    { path: 'about-us', component: HomeThreeComponent },
    {path: 'home-four', component: HomeFourComponent},
    {path: 'Where-to-buy', component: HomeFiveComponent},
    {path: 'Home', component: HomeSevenComponent},
    {path: 'Downloads', component: HomeEightComponent},
    {path: 'Online-service-center', component: HomeNineComponent},
    {path: 'home-ten', component: HomeTenComponent},
    {path: 'solutions', component: HomeElevenComponent},
    {path: 'home-twelve', component: HomeTwelveComponent},
    {path: 'home-thirteen', component: HomeThirteenComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'service', component: ServicioComponent},
    {path: 'charging', component: ChargingComponent},
    { path: 'project-to-quote', component: ProjectToQuoteComponent },
    {path: 'customer-interested', component: CustomerInterestedComponent},
    {path: 'other-inquiries', component: OtherInquiriesComponent},
    {path: '**', component: HomeOneComponent} 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }