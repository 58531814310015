import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home-seven',
  templateUrl: './home-seven.component.html',
  styleUrls: ['./home-seven.component.scss']
})
export class HomeSevenComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

  isText1Visible: boolean = false;
  isText2Visible: boolean = false;
  isText3Visible: boolean = false;

  hideText1(): void {
    this.isText1Visible = false;
  }

  hideText2(): void {
    this.isText2Visible = false;
  }

  hideText3(): void {
    this.isText3Visible = false;
  }

  showText1(): void {
    this.isText1Visible = true;
  }

  showText2(): void {
    this.isText2Visible = true;
  }

  showText3(): void {
    this.isText3Visible = true;
  }

}
