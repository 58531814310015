<div class="main-banner-home"> 
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-texts">
                    <h1 class=" mobile-font-size-sm ">Soluciones de almacenamiento <br>de energía para Latinoamérica</h1>
                    <p >Tecnología de vanguardia y experiencia local para optimizar tu consumo energético.</p><br><br><br>
                    <div class="col-lg-12 col-md-12 ">
                        <a routerLink="/about-us"><button type="submit" class="btn btn-second">Más información →</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-banners main-banner-imagen" >
    <div class="d-table hresp">
        <div class="d-table-cell">
            <div class="container">
                <div class="card-cea"style="opacity: 0.96; ">
                   <div class="card-body" >
                    <div class="row align-items-center ">
                      <div class="col-lg-5 col-md-12 col-sm-12">
                          <div class="about-content">
                              <div class="section-title">
                                  <h1 class="what-cea titulocea "style="text-align: left">QUIÉNES SOMOS?</h1><br>
                                  <p class="fuente line-cea">Somos Collective Energy LATAM, parte del grupo Collective Energy, un distribuidor internacional de soluciones de almacenamiento de energía. Nuestra misión es impulsar el crecimiento sostenible en América Latina mediante la provisión de sistemas de almacenamiento de energía avanzados y de alta calidad. Contamos con un equipo de expertos locales y el respaldo de nuestra sólida alianza con BYD, líder mundial en tecnología de baterías LFP.</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-7 col-md-12 text-center">
                          <div class="about-video">
                              <img class="img-what" src="assets/img/LV FLEX LITE.png" alt="about">
                              <div class="video-btn">
                              </div>
                          </div>
                      </div>
                  </div>
                  
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg-fffdfd ptb-100">
  <div class="container">
      <div class="section">
          <div class="">
              <div class="section">
                  <div class="battery-cea-homes bettery mb-5">
                      <span>¿POR QUÉ COLLECTIVE ENERGY LATAM?</span><br>
                  </div>
                  <div class="row row-cols-4 justify-content-center equal-height pb-100">


                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper col">
                              <div class="services-content mb-2">
                                  <h3 class="borders-top">Calidad y rendimiento garantizados</h3>
                                  <span class="subtext">La combinación de los productos superiores de BYD y el servicio excepcional de Collective Energy LATAM asegura soluciones energéticas confiables y de alto rendimiento.</span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/LocalPresence.jpeg" alt="services-img">
                              </div>
                          </div>
                      </div>

                    
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Presencia en toda Latinoamérica</h3>
                                  <span class="subtext">Desde Colombia hasta Chile, Collective Energy LATAM lleva su experiencia y las soluciones innovadoras de BYD a toda la región.</span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/Presencia en toda latinoamerica.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Formación y soporte local</h3>
                                  <span class="subtext">Capacitamos a técnicos locales con programas especializados para asegurar la implementación de soluciones número 1 en Alemania con el respaldo de BYD.</span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/formacion-soporte-local.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Soluciones avanzadas de energía</h3>
                                  <span class="subtext">Proveemos baterías de litio de BYD de alto rendimiento, garantizando almacenamiento de energía eficiente y seguro en toda Latinoamérica.<br></span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/Soluciones avanzadas de energía.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="bg-fffdfd ptb-100">
  <div class="container">
      <div class="section">
          <div class="">
              <div class="section">
                  <div class="battery-cea-homes bettery mb-5">
                      <span>byd battery box</span><br>
                  </div>
                  <h3 style= "margin-bottom: 2%;">Ofrecemos soluciones integrales para satisfacer <br>las necesidades energéticas en cuatro áreas clave:</h3>

                  <div class="row row-cols-4 justify-content-center equal-height pb-100">
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper col">
                              <div class="services-content mb-2">
                                  <h3 class="borders-top">Baterías residenciales</h3>
                                  <span class="subtext">Sistemas de almacenamiento para hogares que garantizan energía eficiente y confiable.</span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/Baterías residenciales.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>

                    
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Comercial e Industrial (C&I)</h3>
                                  <span class="subtext">Soluciones energéticas escalables para empresas que buscan optimizar su uso de energía.
                                  </span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/Commercial e industrial.png" alt="services-img">
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Proyectos a gran escala (Utility scale)</h3>
                                  <span class="subtext">Sistemas de almacenamiento de energía de gran capacidad, ideales para proyectos a gran escala.</span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/Proyectos a gran escala.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-6 col-6">
                        <div class="content-wrapper">
                              <div class="services-content  mb-2">
                                  <h3 class="borders-top">Carga de vehículos eléctricos (EV Charging)</h3>
                                  <span class="subtext">Soluciones completas de carga de vehículos eléctricos, tanto para uso residencial como comercial.<br></span><br>
                              </div>
                              <div class="services-img">
                                  <img style="margin-top: 2%;" src="assets/img/EV Charging.jpg" alt="services-img">
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class=" bg-0B1F0D  pt-100 " >
    <div class="container">
        <div class="section">
          <div class="battery-cea-home text-center ">
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BYD </span>
            <span style="color:#EFE6C0; opacity:0.25" class="d-block">BATTERY BOX</span>
        </div>
    </div>
</div>
</div>
<div class="main-banner ">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-texts">
            <h2 class="texto-blanco">Los sistemas de almacenamiento de<br> energía más sencillos y eficientes:</h2> <br>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img">
                    <img src="assets/img/expansion.svg" height="200" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Instalación sencilla</h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center">
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/layout.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Expansión sencilla</h3><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-center"> 
                  <div class="services-img" style="vertical-align: top;">
                    <img src="assets/img/powerful.svg" height="200px" alt="services-img">
                  </div>
                  <div class=""><br>
                    <h3 class="texto-blanco" style="font-size: 1.3rem;">Simplemente eficiente</h3><br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
