import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-inquiries',
  templateUrl: './confirm-inquiries.component.html',
  styleUrls: ['./confirm-inquiries.component.scss']
})
export class ConfirmInquiriesComponent {
  constructor(private route: Router) {

  }

  redirigir() {
    this.route.navigate(["solutions"]);
  }
}
